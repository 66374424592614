<template>
  <div>
    <page-header :title="title" :container-class="containerClass" :info-text="''" />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    :placeholder="$t('studentMsgs.searchStudents')"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <div class="col-lg-7 px-0" :class="isTabSmallScreen ? 'mt-2 mb-2' : 'mx-0'">
                  <v-select
                    id="simulationFilter"
                    class="form-control v-select-custom"
                    label="title"
                    :value="simulationFilter"
                    :reduce="simulation => simulation.title"
                    placeholder="Select simulation"
                    :options="allSimulations"
                    :loading="areSimulationsLoading"
                    @input="setSchFilter"
                  >
                    <template #search="{ attributes, events }">
                      <input class="vs__search" v-bind="attributes" v-on="events" />
                    </template>

                    <template slot="option" slot-scope="option">
                      <span>{{ option.title }}</span>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ truncate(option.title, { length: 20 }) }}
                    </template>
                  </v-select>
                </div>
                <!-- TODO: Add filters here. (if needed) -->
              </div>
            </div>
          </div>

          <!-- Students Table -->
          <b-table
            :fields="tableColumns"
            :items="students"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @row-clicked="onRowClicked"
            @sort-changed="onSortChange"
          >
            <template #cell(name)="data">
              {{ data.item.student.user.first_name }} {{ data.item.student.user.last_name }}
            </template>

            <template #cell(simulation)="data">
              {{ data.item.simulation.title }}
            </template>

            <template #cell(school)="data">
              {{ data.item.student.school.name }}
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalStudents"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="students-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, truncate } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import MdIcon from '@/components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE, USER_ROLE_TYPES } from '@/common/constants';
import { formatFullDate } from '@/common/utils';
export default {
  components: { MdIcon, PageHeader, Pagination },
  extends: Page,

  data() {
    return {
      title: 'Simulation Submissions',
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalStudents: 0,
      students: [],
      searchTerm: '',
      areSimulationsLoading: false,
      allSimulations: [],
      simulationFilter: '',
      USER_ROLE_TYPES,
      windowWidth: window.innerWidth
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: this.$t('studentMsgs.students'), active: true }
      ];
    },

    tableColumns() {
      return [
        { key: 'name', label: this.$t('studentMsgs.name'), tdClass: 'clickable-item' },
        {
          key: 'simulation',
          label: 'Simulation',
          tdClass: 'clickable-item',
          formatter: value => (value ? formatFullDate(value) : value)
        },
        {
          key: 'school',
          label: 'School',
          tdClass: 'clickable-item',
          formatter: value => (value ? formatFullDate(value) : value)
        },
        { key: 'current_status', label: 'Status', tdClass: 'clickable-item' },
        {
          key: 'updated_at',
          label: 'Applied On',
          tdClass: 'clickable-item',
          formatter: value => (value ? formatFullDate(value) : value)
        }
      ];
    }
  },

  methods: {
    ...mapActions('applicationForm', ['getAllSchApps']),
    ...mapActions('simulations', ['getAllSimulations', 'getSimulationsSubmissions']),
    truncate,
    onRowClicked(data) {
      this.$router.push({
        name: 'simulations-submission-profile',
        params: { id: data.id }
      });
    },
    setSchFilter(value) {
      this.simulationFilter = value;
      this.$router.replace({
        name: 'simulations-submission-list',
        query: {
          ...(value && { simulation: value })
        }
      });
      this.fetchStudents();
    },
    async fetchSimulations() {
      this.areSimulationsLoading = true;

      try {
        const response = await this.getAllSimulations({});

        this.allSimulations = response.data;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areSimulationsLoading = false;
    },

    async fetchStudents(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getSimulationsSubmissions({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...(this.simulationFilter && { simulation__title: this.simulationFilter }),
        ...params
      });
      this.students = response.data.results;
      this.currentPage = pageNum;
      this.totalStudents = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchStudents(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchStudents();
    },

    onSearch() {
      this.debouncedSearchStudents(this);
    },

    debouncedSearchStudents: debounce(vm => {
      vm.fetchStudents();
    }, 500),
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },

  async mounted() {
    this.fetchSimulations();
    if (this.$route.query) {
      this.simulationFilter = this.$route.query.simulation;
    }
    this.fetchStudents();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>
