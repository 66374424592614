<template>
  <div :class="containerClass">
    <page-header
      :title="
        $route.query.signupRequestId
          ? 'Approve School Account'
          : this.$route.params.id
          ? this.$t('schoolMsgs.editSchool')
          : this.$t('schoolMsgs.addSchool')
      "
      :breadcrumb="breadcrumb"
      :container-class="null"
      class="mb-32pt"
    />

    <page-separator :title="$t('schoolMsgs.schoolInfo')" />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <!-- Basic School Info -->
      <b-form-group class="row-align-items-center">
        <label class="form-label">
          Logo
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="`Ideal dimensions for the logo should be 1280*720.`"
            >info</i
          >
        </label>
        <b-media class="align-items-center" vertical-align="center">
          <!-- <user-avatar slot="aside" rounded="lg" size="lg" :isLoading="isLogoUploading" :file-url="owner.logoUrl">
          </user-avatar> -->

          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isLogoUploading">...</span>
            <b-img :src="school.logoUrl" class="img-fluid" width="40" alt="Logo" v-else-if="school.logoUrl" />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'logo')"
            :isUploading="isLogoUploading"
            :isRequired="!school.logoUrl"
            :placeholder="$t('schoolMsgs.logoDesc')"
          />
        </b-media>
      </b-form-group>
      <b-form-group label-for="small_logo_url" class="row-align-items-center">
        <label class="form-label">
          Small Logo
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="
              `Ideal dimensions for small logo should be 512*512. This will be shown as app's favicon.`
            "
            >info</i
          >
        </label>
        <b-media class="align-items-center" vertical-align="center">
          <!-- <user-avatar slot="aside" rounded="lg" size="lg" :isLoading="isLogoUploading" :file-url="owner.logoUrl">
          </user-avatar> -->

          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isSmallLogoUploading">...</span>
            <b-img
              :src="school.small_logo_url"
              class="img-fluid"
              width="40"
              alt="Logo"
              v-else-if="school.small_logo_url"
            />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'smallLogo')"
            :isUploading="isSmallLogoUploading"
            :isRequired="!school.small_logo_url"
            placeholder="Select School's Small Logo Picture"
          />
        </b-media>
      </b-form-group>
      <b-form-group :label="$t('schoolMsgs.name')" label-for="name" label-class="form-label">
        <b-form-input id="name" :placeholder="$t('schoolMsgs.namePlaceholder')" v-model="school.name" required />
      </b-form-group>

      <b-form-group label="Contact email" label-for="email" label-class="form-label">
        <b-form-input id="email" placeholder="Enter school's contact email" v-model="school.email" type="email" />
      </b-form-group>

      <b-form-group :label="$t('schoolMsgs.phone')" label-for="phone" label-class="form-label">
        <b-form-input
          id="phone"
          placeholder="(888) 689 - 1235"
          v-model="school.phone"
          v-mask="'(###) ### - ####'"
          required
        />
      </b-form-group>

      <b-form-group :label="$t('schoolMsgs.website')" label-for="website" label-class="form-label">
        <b-form-input id="website" :placeholder="$t('schoolMsgs.websitePlaceholder')" v-model="school.websiteUrl" />
      </b-form-group>
      <b-form-group
        :label="$t('schoolMsgs.subdomain')"
        label-for="subdomain"
        label-class="form-label"
        v-if="$route.params.id"
      >
        <b-form-input id="subdomain" :value="school.subdomain" disabled />
      </b-form-group>

      <!-- Address Fields -->
      <b-form-group :label="$t('schoolMsgs.address')" label-for="address" label-class="form-label">
        <b-form-input id="address" :placeholder="$t('schoolMsgs.address')" v-model="school.address" required />
      </b-form-group>

      <div class="row">
        <b-form-group :label="$t('schoolMsgs.zipcode')" label-for="zipcode" label-class="form-label" class="col-md-6">
          <b-form-input id="zipcode" :placeholder="$t('schoolMsgs.zipcode')" v-model="school.zipcode" required />
        </b-form-group>

        <b-form-group :label="$t('schoolMsgs.city')" label-for="city" label-class="form-label" class="col-md-6">
          <b-form-input id="city" :placeholder="$t('schoolMsgs.city')" v-model="school.city" required />
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group :label="$t('schoolMsgs.state')" label-for="state" label-class="form-label" class="col-md-6">
          <v-select
            id="state"
            class="form-control v-select-custom"
            label="text"
            v-model="school.state"
            :reduce="state => state.value"
            placeholder="Select State"
            :options="stateOptions"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!school.state" v-bind="attributes" v-on="events" />
            </template>
          </v-select>
        </b-form-group>

        <b-form-group :label="$t('schoolMsgs.country')" label-for="country" label-class="form-label" class="col-md-6">
          <v-select
            id="country"
            class="form-control v-select-custom"
            label="text"
            disabled
            v-model="school.country"
            :reduce="country => country.value"
            :placeholder="$t('schoolMsgs.countryPlaceholder')"
            :options="countryOptions"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!school.country" v-bind="attributes" v-on="events" />
            </template>
          </v-select>
        </b-form-group>
      </div>

      <b-form-group label="Profile Visibility" label-for="about" label-class="form-label">
        <b-form-select v-model="school.is_profile_visible" :options="PROFILE_VISIBILITY_OPTIONS"></b-form-select>
      </b-form-group>

      <!-- Other Details -->

      <b-form-group label="Short Bio" label-for="short_bio" label-class="form-label">
        <b-form-textarea
          id="short_bio"
          placeholder="Enter school's short/summarized bio."
          :rows="5"
          v-model="school.short_bio"
        />
      </b-form-group>
      <b-form-group label="Bio" label-for="bio" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
          }"
          v-model="school.bio"
          placeholder="Enter school's bio"
        />
      </b-form-group>

      <b-form-group
        :label="$t('schoolMsgs.profileBgImage')"
        label-for="profileBgImage"
        label-class="form-label"
        class="row-align-items-center"
      >
        <b-media class="align-items-center" vertical-align="center">
          <image-uploader
            @image="e => setImage(e, 'profileBgImage')"
            :isUploading="isProfileBgImgUploading"
            :isRequired="false"
            :placeholder="$t('schoolMsgs.profileBgImageDesc')"
          />
        </b-media>
      </b-form-group>

      <page-separator title="Careers Settings" />

      <b-form-group label-class="form-label">
        <b-form-checkbox v-model="school.std_portal_uni" name="check-button" switch>
          Show university career paths to students?
        </b-form-checkbox>
      </b-form-group>

      <b-form-group :label="$t('schoolMsgs.offeredPrg')" label-for="offered_programs" label-class="form-label">
        <v-select
          id="offered_programs"
          class="form-control v-select-custom"
          label="title"
          v-model="school.offered_programs"
          :reduce="offered_programs => offered_programs.id"
          placeholder="Select Offered Program"
          :options="allPrograms"
          multiple
          :loading="areProgramsLoading"
          :class="
            !$v.school.offered_programs.required && $v.school.offered_programs.$dirty ? 'form-control is-invalid' : ''
          "
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!school.offered_programs" v-bind="attributes" v-on="events" />
          </template>
          <template slot="option" slot-scope="option">
            <span>{{ option.title }}</span>
          </template>
        </v-select>
        <b-form-invalid-feedback
          :state="!$v.school.offered_programs.required && $v.school.offered_programs.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label="VideoAsk Skill Share Id" label-for="skill_share_id" label-class="form-label">
        <b-form-input id="skill_share_id" placeholder="Enter VideoAsk Skill Share Id" v-model="school.skill_share_id" />
      </b-form-group>

      <b-form-group :label="$t('schoolMsgs.homeTitle')" label-for="pp_home_title" label-class="form-label">
        <b-form-input id="pp_home_title" v-model="school.pp_home_title" required />
      </b-form-group>

      <b-form-group :label="$t('schoolMsgs.homeDescription')" label-for="pp_home_description" label-class="form-label">
        <b-form-input id="pp_home_description" v-model="school.pp_home_description" required />
      </b-form-group>

      <b-form-group
        :label="$t('schoolMsgs.testimonialTitle')"
        label-for="pp_testimonial_title"
        label-class="form-label"
      >
        <b-form-input id="pp_testimonial_title" v-model="school.pp_testimonial_title" required />
      </b-form-group>
      <b-form-group :label="$t('schoolMsgs.publicCTA')" label-for="pp_cta_link_text" label-class="form-label">
        <input id="pp_cta_link_text" v-model="school.pp_cta_link_text" class="form-control" required maxlength="15" />
      </b-form-group>
      <b-form-group
        :label="$t('schoolMsgs.testimonialDescription')"
        label-for="pp_testimonial_description"
        label-class="form-label"
      >
        <b-form-input id="pp_testimonial_description" v-model="school.pp_testimonial_description" required />
      </b-form-group>

      <div class="row">
        <b-form-group
          :label="$t('schoolMsgs.primaryColor')"
          label-for="primaryColor"
          label-class="form-label"
          class="col-md-4"
        >
          <v-swatches
            v-model.trim="school.primaryColor"
            shapes="circles"
            show-fallback
            :swatches="swatches"
            fallback-input-type="color"
            :fallback-ok-text="$t('generalMsgs.select')"
            fallback-input-class="py-0"
          ></v-swatches>
        </b-form-group>
        <b-form-group
          :label="$t('schoolMsgs.secondaryColor')"
          label-for="secondaryColor"
          label-class="form-label"
          class="col-md-4"
        >
          <v-swatches
            v-model.trim="school.secondaryColor"
            shapes="circles"
            show-fallback
            :swatches="swatches"
            fallback-input-type="color"
            :fallback-ok-text="$t('generalMsgs.select')"
            fallback-input-class="py-0"
          ></v-swatches>
        </b-form-group>
        <b-form-group
          :label="$t('schoolMsgs.darkColor')"
          label-for="darkColor"
          label-class="form-label"
          class="col-md-4"
        >
          <v-swatches
            v-model.trim="school.darkColor"
            shapes="circles"
            show-fallback
            :swatches="swatches"
            fallback-input-type="color"
            :fallback-ok-text="$t('generalMsgs.select')"
            fallback-input-class="py-0"
          ></v-swatches>
        </b-form-group>
      </div>

      <b-form-group label="Instituition Type" label-for="about" label-class="form-label">
        <b-form-select
          v-model="instituitionType"
          placeholder="Select instituition type"
          :options="INSTITUTION_TYPE_OPTIONS"
        ></b-form-select>
      </b-form-group>

      <b-form-group label="Enrollment Link" label-for="enrollment-link" label-class="form-label">
        <b-form-input id="enrollment-link" placeholder="Enter enrollment link" v-model="enrollLink" />
      </b-form-group>

      <b-form-group label="Payment Plan" label-for="payment_plan" label-class="form-label">
        <v-select
          id="payment_plan"
          class="form-control v-select-custom"
          label="title"
          v-model="school.payment_plan"
          :reduce="item => item.id"
          placeholder="Select Payment Plan"
          :options="paymentPlanOptions"
          :clearable="false"
          :loading="arePaymentPlansLoading"
        >
        </v-select>
      </b-form-group>

      <b-form-group label="Ai Assistant Project ID" label-for="std_bot_api_key" label-class="form-label">
        <b-form-input id="std_bot_api_key" placeholder="Enter Api key" v-model="std_bot_api_key" />
      </b-form-group>

      <page-separator title="Partnership Settings" />

      <b-form-group label-class="form-label">
        <b-form-checkbox v-model="freePartnership" name="check-button" switch> Make partnership free? </b-form-checkbox>
      </b-form-group>

      <b-form-group
        label="Initiation Fee / Recently Active Student (Charged Once)"
        label-for="partner_initial_fee"
        label-class="form-label"
        v-if="!freePartnership"
      >
        <currency-input
          v-model="school.partner_initial_fee"
          required
          class="form-control"
          placeholder="Enter initiation fee per student"
          :allow-negative="false"
        />
      </b-form-group>
      <b-form-group
        label="Recurring Fee (Charged Monthly)"
        label-for="partner_recurring_fee"
        label-class="form-label"
        v-if="!freePartnership"
      >
        <currency-input
          v-model="school.partner_recurring_fee"
          required
          class="form-control"
          placeholder="Enter recurring fee"
          :allow-negative="false"
        />
      </b-form-group>
      <b-form-group
        label="Employer Reference Fee (Charged Per Hire)"
        label-for="partner_reference_fee"
        label-class="form-label"
      >
        <currency-input
          v-model="school.partner_reference_fee"
          required
          class="form-control"
          placeholder="Enter reference fee"
          :allow-negative="false"
        />
      </b-form-group>

      <b-form-group label="Employer Partnership Terms" label-for="interview_tips" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
          }"
          v-model="school.partnership_terms"
          placeholder="Enter Partnership Terms"
        />
        <!-- <b-form-invalid-feedback
          :state="!$v.school.partnership_terms.required && $v.school.partnership_terms.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        > -->
      </b-form-group>
      <b-form-group>
        <label class="form-label">
          Partnered Organizations
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="`All employers of newly added organizations will be partnered with this school.`"
            >info</i
          >
        </label>
        <v-select
          id="organizations"
          class="form-control v-select-custom"
          label="name"
          v-model="school.organizations"
          :reduce="org => org.id"
          placeholder="Select Organization"
          :options="allOrgs"
          multiple
          :loading="areOrgsLoading"
        >
          <template slot="option" slot-scope="option">
            <span>{{ option.name }}</span>
          </template>
        </v-select>
      </b-form-group>

      <!-- <b-form-group
        label="Partnership Overview Thumbnail"
        label-for="partnershipImage"
        label-class="form-label"
        class="row-align-items-center"
      >
        <b-media class="align-items-center" vertical-align="center">
          <b-form-file
            id="partnershipImage"
            placeholder="Select image"
            :browse-text="$t('generalMsgs.browse')"
            v-model="uploadFile.partnershipImage"
            @input="uploadImage('partnershipImage')"
            :disabled="isPartnershipImageUploading"
            accept="image/*"
          />
        </b-media>
      </b-form-group> -->

      <b-form-group
        label="Partnership Overview Video"
        label-for="partnership_video"
        label-class="form-label"
        class="row-align-items-center"
        v-if="!school.partnershipOverviewVideoUrl"
      >
        <b-media class="align-items-center" vertical-align="center">
          <b-form-file
            id="partnership_video"
            placeholder="Upload a video to showcase on employer overview"
            :browse-text="$t('generalMsgs.browse')"
            v-model="uploadFile.partnershipOverviewVideo"
            @input="uploadImage('partnershipOverviewVideo')"
            :disabled="isPartnershipVideoUploading"
            accept="video/mp4, video/webm"
          />
        </b-media>
      </b-form-group>

      <b-form-group
        v-if="school.partnershipOverviewVideoUrl"
        label="Partnership Overview Video Preview"
        label-for="partnership_video"
        label-class="form-label"
      >
        <div class="d-flex justify-content-end mb-3">
          <b-btn variant="danger" @click="school.partnershipOverviewVideoUrl = ''"> Remove </b-btn>
        </div>
        <video width="100%" height="200" controls>
          <source :src="school.partnershipOverviewVideoUrl" type="video/mp4" />
        </video>
      </b-form-group>

      <!-- CareCampus/Sponsored Training School Details -->

      <page-separator title="CareCampus/Sponsored Training Profile" />

      <b-form-group class="row-align-items-center">
        <label class="form-label">
          School Image
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="`Ideal dimensions for the logo should be 1280*720.`"
            >info</i
          >
        </label>
        <b-media class="align-items-center" vertical-align="center">
          <!-- <user-avatar slot="aside" rounded="lg" size="lg" :isLoading="isLogoUploading" :file-url="owner.logoUrl">
          </user-avatar> -->

          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isLogoUploading">...</span>
            <b-img :src="bestSchoolImgUrl" class="img-fluid" width="40" alt="Logo" v-else-if="bestSchoolImgUrl" />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'BestSchoolImg')"
            :isUploading="isLogoUploading"
            :isRequired="false"
            placeholder="Select school's image"
          />
        </b-media>
      </b-form-group>

      <div class="row">
        <b-form-group label="States" label-for="state" label-class="form-label" class="col-md-6">
          <v-select
            id="state"
            class="form-control v-select-custom"
            label="name"
            v-model="bestStates"
            :reduce="bestStates => bestStates.id"
            placeholder="Select the states to show school profile in"
            :loading="areBestStatesLoading"
            :options="bestStateOptions"
            multiple
            @input="getCities()"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!school.state" v-bind="attributes" v-on="events" />
            </template>
          </v-select>
          <!-- <b-form-input id="state" :placeholder="$t('schoolMsgs.state')" v-model="school.state" required /> -->
        </b-form-group>

        <b-form-group label="High School District" label-for="state" label-class="form-label" class="col-md-6">
          <v-select
            id="state"
            class="form-control v-select-custom"
            label="name"
            v-model="bestCities"
            :reduce="bestCities => customReduce(bestCities)"
            placeholder="Select the cities to show school profile in"
            :loading="areBestCitiesLoading"
            :options="bestCitiesOptions"
            multiple
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!school.state" v-bind="attributes" v-on="events" />
            </template>

            <template slot="option" slot-scope="option">
              <div class="d-flex align-items-center">
                <span>{{ option.name }}, {{ option.state.name }}</span>
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              {{ option.name }}
            </template>
          </v-select>
          <!-- <b-form-input id="state" :placeholder="$t('schoolMsgs.state')" v-model="school.state" required /> -->
        </b-form-group>
      </div>

      <b-form-group label="Class Tuition Range" label-for="classTuitionRange" label-class="form-label">
        <div class="row">
          <b-form-group label="From" label-for="fromClassTuition" label-class="form-label" class="col-md-6">
            <currency-input
              id="fromClassTuition"
              v-model="fromClassTuition"
              class="form-control"
              placeholder="Enter tuition fee range"
              :allow-negative="false"
              :currency="{ prefix: '$', suffix: '' }"
            />
          </b-form-group>
          <b-form-group label="To" label-for="toClassTuition" label-class="form-label" class="col-md-6">
            <currency-input
              id="toClassTuition"
              v-model="toClassTuition"
              class="form-control"
              placeholder="Enter tuition fee range"
              :allow-negative="false"
              :currency="{ prefix: '$', suffix: '' }"
            />
          </b-form-group>
        </div>
      </b-form-group>

      <b-form-group label="Pass Rate" label-for="passRatio" label-class="form-label">
        <currency-input
          id="passRatio"
          v-model="passRatio"
          class="form-control"
          placeholder="Enter pass percentage"
          :allow-negative="false"
          :value-range="{ min: 0, max: 100 }"
          :currency="{ prefix: '', suffix: '%' }"
        />
      </b-form-group>

      <b-form-group label="Average Program Weeks" label-for="avgProgramWeeks" label-class="form-label">
        <b-form-input
          id="avgProgramWeeks"
          v-model="avgProgramWeeks"
          class="form-control"
          placeholder="Enter average program weeks"
          type="number"
          :allow-negative="false"
        />
      </b-form-group>

      <b-form-group label="School Offerings" label-for="bestSchoolOfferings" label-class="form-label">
        <v-select
          id="bestSchoolOfferings"
          class="form-control v-select-custom"
          label="text"
          v-model="bestSchoolOfferings"
          :reduce="item => item.value"
          placeholder="Select school's offerings"
          :options="BestSchoolOfferingsOptions"
          multiple
        >
        </v-select>

        <b-form-input
          id="other_offerings"
          class="mt-2"
          placeholder="Please specify."
          v-model="otherOfferings"
          required
          v-if="bestSchoolOfferings.includes('other')"
        />
      </b-form-group>

      <b-form-group label="Google Place Id" label-for="place_id" label-class="form-label">
        <b-form-input id="place_id" placeholder="Enter Google Place Id (For Reviews)" v-model="place_id" />
      </b-form-group>

      <b-form-group label="School Overview" label-for="bio" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
          }"
          v-model="school.bestSchoolDesc"
          placeholder="Enter school's overview/description"
        />
      </b-form-group>

      <b-form-group label="Short Overview/Description" label-for="short_review" label-class="form-label">
        <b-form-textarea
          id="short_review"
          placeholder="Enter school's short/summarized description."
          :rows="5"
          v-model="shortReview"
        />
      </b-form-group>

      <b-form-group label="School Review" label-for="review" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
          }"
          v-model="schoolReview"
          placeholder="Enter our school review to be displayed on school profile."
        />
      </b-form-group>

      <div class="row">
        <b-form-group class="col-md-6">
          <b-form-checkbox id="checkbox-1" v-model="enrollBtn" name="checkbox-1" switch>
            Enrollment Button Active
          </b-form-checkbox>
        </b-form-group>
        <b-form-group class="col-md-6">
          <b-form-checkbox id="checkbox-2" v-model="websiteBtn" name="checkbox-2" switch>
            Website Button Active
          </b-form-checkbox>
        </b-form-group>
      </div>

      <!-- Owner Details -->
      <div v-if="!$route.params.id">
        <page-separator :title="$t('schoolMsgs.ownerInfo')" />

        <div class="row">
          <b-form-group
            :label="$t('userMsgs.firstName')"
            label-for="firstname"
            label-class="form-label"
            class="col-md-6"
          >
            <b-form-input id="firstname" :placeholder="$t('userMsgs.firstName')" v-model="owner.firstName" required />
          </b-form-group>

          <b-form-group :label="$t('userMsgs.lastName')" label-for="lastname" label-class="form-label" class="col-md-6">
            <b-form-input id="lastname" :placeholder="$t('userMsgs.lastName')" v-model="owner.lastName" required />
          </b-form-group>
        </div>

        <b-form-group :label="$t('authMsgs.emailAddr')" label-for="email" label-class="form-label">
          <b-form-input
            id="email"
            :placeholder="$t('authMsgs.emailAddr')"
            v-model="owner.email"
            type="email"
            required
            :state="errorStates.ownerEmail"
          />
          <b-form-invalid-feedback>{{ $t('userMsgs.userAlreadyExists') }}</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group :label="$t('authMsgs.password')" label-for="password" label-class="form-label">
          <b-alert variant="soft-primary px-12pt" show>
            <div class="d-flex align-items-center justify-content-between">
              <span class="text-black-100"
                >Auto-generated password: <strong>{{ owner.password }}</strong></span
              >

              <span v-if="isPassCopied" class="text-success"><md-icon>check</md-icon> Copied</span>
              <a href="#" @click.prevent="copyPass" v-else v-b-popover.hover.top="'Copy Password'">
                <md-icon>content_copy</md-icon>
              </a>
            </div>
          </b-alert>
          <!-- <b-form-input
            id="password"
            :placeholder="$t('authMsgs.password')"
            v-model="owner.password"
            required
            type="password"
            :state="errorStates.password"
          />
          <b-form-invalid-feedback>{{ $t(`passValidationMsgs.${passErrorCode}`) }}</b-form-invalid-feedback>-->
        </b-form-group>
      </div>

      <!-- Student demo account -->
      <page-separator title="Student Demo Account" />
      <div class="row">
        <b-form-group label="Account Email" label-for="demo_email" label-class="form-label" class="col-md-6">
          <b-form-input
            id="demo_email"
            placeholder="Enter demo account's email"
            v-model="school.demo_email"
            autocomplete="off"
          />
        </b-form-group>

        <b-form-group label="Account Password" label-for="demo_password" label-class="form-label" class="col-md-6">
          <form class="search-form search-form d-lg-inline-flex mb-8pt mb-lg-0 w-100" @submit.prevent>
            <b-form-input
              id="demo_password"
              placeholder="Enter demo account's password"
              v-model="school.demo_password"
              :type="school.demo_password && !showStdDemoPass ? 'password' : 'text'"
              autocomplete="off"
            />
            <b-btn variant="flush" @click.prevent="() => (showStdDemoPass = !showStdDemoPass)">
              <md-icon>{{ showStdDemoPass ? 'visibility_off' : 'visibility' }}</md-icon>
            </b-btn>
          </form>
        </b-form-group>
      </div>

      <page-separator title="CareCampus Universities" />

      <b-form-group>
        <b-form-checkbox id="checkbox-uni" v-model="showUniCareCampus" name="checkbox-uni" switch>
          Show On CareCampus universities?
        </b-form-checkbox>
      </b-form-group>

      <!-- Photo Gallery -->
      <page-separator title="Photo Gallery Upload" />
      <b-form-group label="" label-for="review" label-class="form-label">
        <image-uploader @image="upload" :uploadBtnOnly="true" :isPictureLoading="isPictureLoading" />

        <div class="d-flex flex-wrap mt-2">
          <div
            style="position: relative"
            class="mr-2 mb-2"
            v-for="(photo, index) of photo_gallery"
            :key="`photo-${index}`"
          >
            <b-img
              :src="photo"
              class="img-fluid"
              style="
                height: 6rem;
                width: 7rem;
                color: white;
                border-radius: 0.5rem;
                border: solid gray 0.1px !important;
                object-fit: cover;
                min-width: 5rem;
              "
              alt="Logo"
            />
            <!-- <b-button color="transparent" class="float-circle-btn" > -->
            <a
              class="clickable-item"
              @click.prevent="removeVisual(index)"
              style="color: #214163; position: absolute; top: 0; right: 2px"
              ><i class="fas fa-times-circle"></i
            ></a>
            <!-- </b-button> -->
          </div>
        </div>
      </b-form-group>

      <!-- Healthcare Details -->

      <page-separator title="HealthcareEnroll Account" />

      <b-form-group :disabled="$route.params.id && cnaAccRes">
        <b-form-checkbox id="checkbox-3" v-model="cnaAcc" name="checkbox-3" switch>
          Auto-create school account on HealthcareEnroll?
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="`Healthcare enroll account creation cannot be reverted, once created.`"
            >info</i
          >
        </b-form-checkbox>
      </b-form-group>

      <div v-if="cnaAcc">
        <b-form-group label="School Type" label-for="school_type" label-class="form-label">
          <v-select
            id="school_type"
            class="form-control v-select-custom"
            label="title"
            v-model="cnaAccDetails.school_type"
            :reduce="item => item.value"
            placeholder="Select school type"
            :options="schoolTypeOptions"
            :clearable="false"
            :disabled="$route.params.id && cnaAccRes"
          >
          </v-select>
        </b-form-group>

        <b-form-group label="Payment Plan" label-for="paymentPlan" label-class="form-label">
          <v-select
            id="paymentPlan"
            class="form-control v-select-custom"
            label="title"
            v-model="cnaAccDetails.paymentPlan"
            :reduce="paymentPlan => paymentPlan"
            placeholder="Select plan"
            :options="cnaAccDetails.allPaymentPlans"
            :loading="cnaAccDetails.arePaymentPlansLoading"
            :class="
              !$v.cnaAccDetails.paymentPlan.required && $v.cnaAccDetails.paymentPlan.$dirty
                ? 'form-control is-invalid'
                : ''
            "
            :clearable="false"
          >
            <template slot="option" slot-scope="option">
              <div class="d-flex align-items-center">
                <span>{{ $t(`cna_school_payment_plans.${option.title}`) }}</span>
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              {{ $t(`cna_school_payment_plans.${option.title}`) }}
            </template>
          </v-select>
          <b-form-invalid-feedback
            :state="!$v.cnaAccDetails.paymentPlan.required && $v.cnaAccDetails.paymentPlan.$dirty ? false : null"
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group v-if="get(cnaAccDetails.paymentPlan, 'title') === CNA_PAYMENT_PLANS.ADVANCED">
          <b-form-checkbox
            id="checkbox-4"
            v-model="cnaAccDetails.published"
            name="checkbox-4"
            switch
            :required="get(cnaAccDetails.paymentPlan, 'title') === CNA_PAYMENT_PLANS.ADVANCED ? true : false"
            >Website Published?
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          v-if="cnaAccDetails.published && get(cnaAccDetails.paymentPlan, 'title') === CNA_PAYMENT_PLANS.ADVANCED"
          label="Linked Website"
          label-for="linked-website"
          label-class="form-label"
        >
          <b-form-input
            id="linked-website"
            placeholder="Enter linked website domain"
            v-model="cnaAccDetails.linkedWeb"
            :required="cnaAccDetails.published ? true : false"
          />
        </b-form-group>

        <b-form-group>
          <b-form-checkbox id="checkbox-inapp" v-model="inappPaymentEnable" name="checkbox-inapp" switch>
            Is In-app payment enabled?
            <i
              class="material-icons icon-16pt clickable-item text-muted mb-1"
              v-b-popover.hover.top="`Allow students to pay their pending payment plans via transition.`"
              >info</i
            >
          </b-form-checkbox>
        </b-form-group>

        <b-form-group label="Stripe Customer Portal Link" label-for="cna_customer" label-class="form-label">
          <b-form-input
            id="cna-customer"
            placeholder="Enter the stripe customer portal link"
            v-model="cnaAccDetails.customerPortalLink"
          />
        </b-form-group>

        <b-form-group
          :label="$t('schoolMsgs.profileBgImage')"
          label-for="cnaProfileBgImage"
          label-class="form-label"
          class="row-align-items-center"
        >
          <b-media class="align-items-center" vertical-align="center">
            <image-uploader
              @image="e => setImage(e, 'cnaProfileBgImage')"
              :isUploading="isCnaProfileBgImgUploading"
              :isRequired="false"
              :placeholder="$t('schoolMsgs.profileBgImageDesc')"
            />
          </b-media>
        </b-form-group>

        <b-form-group :label="$t('schoolMsgs.homeTitle')" label-for="cna_pp_home_title" label-class="form-label">
          <b-form-input id="cna_pp_home_title" v-model="cnaAccDetails.pp_home_title" maxlength="100" />
        </b-form-group>

        <b-form-group
          :label="$t('schoolMsgs.homeDescription')"
          label-for="cna_pp_home_description"
          label-class="form-label"
        >
          <b-form-input id="cna_pp_home_description" v-model="cnaAccDetails.pp_home_description" maxlength="250" />
        </b-form-group>

        <b-form-group>
          <b-form-checkbox id="checkbox-banner" v-model="cnaAccDetails.showBannerStatus" name="checkbox-banner" switch>
            Display description on public page
          </b-form-checkbox>
        </b-form-group>

        <b-form-group :label="$t('schoolMsgs.publicCTA')" label-for="cna_pp_cta_link_text" label-class="form-label">
          <input
            id="cna_pp_cta_link_text"
            v-model="cnaAccDetails.pp_cta_link_text"
            class="form-control"
            required
            maxlength="15"
          />
        </b-form-group>
        <b-form-group>
          <label>
            Registration Page Description
            <i
              class="material-icons icon-16pt clickable-item text-muted"
              v-b-popover.hover.top="
                `Use {{application_fee}} and {{tuition_fee}} bookmarks for showing relevant program fees in the description.`
              "
              >info</i
            >
          </label>
          <b-form-textarea
            id="cna_pp_registration_desc"
            :placeholder="
              `Note: A non-refundable application fee of {{application_fee }} is required for online registration submissions. Before registering, please make sure to contact us to make sure all of your questions are answered.`
            "
            v-model="cnaAccDetails.pp_registration_desc"
            :rows="3"
          />
        </b-form-group>
        <b-form-group label="Product Page Description" label-for="cna_pp_products_desc" label-class="form-label">
          <b-form-textarea
            id="cna_pp_products_desc"
            placeholder="View and add additional merchandise, uniforms, and supplies from our invetory of products below. All orders will be received on the date of class orientation."
            v-model="cnaAccDetails.pp_products_desc"
            :rows="3"
          />
        </b-form-group>
        <!-- <b-form-group label="Pre Enrollment Page Description" label-for="cna_pp_enroll_desc" label-class="form-label">
          <b-form-textarea
            id="cna_pp_enroll_desc"
            placeholder="View and add additional merchandise, uniforms, and supplies from our invetory of products below. All orders will be received on the date of class orientation."
            v-model="cnaAccDetails.pp_enroll_desc"
            :rows="3"
          />
        </b-form-group> -->
        <b-form-group
          label="Important Visitor Notice Title"
          label-for="cna_visitor_notice_title"
          label-class="form-label"
        >
          <b-form-input
            id="cna_visitor_notice_title"
            placeholder="Enter Visitor Notice Title"
            v-model="cnaAccDetails.visitor_notice_title"
          />
        </b-form-group>

        <b-form-group
          label="Important Visitor Notice Description"
          label-for="cna_visitor_notice_desc"
          label-class="form-label"
        >
          <b-form-textarea
            id="cna_visitor_notice_desc"
            placeholder="Enter Visitor Notice Description"
            v-model="cnaAccDetails.visitor_notice_desc"
            :rows="5"
          />
        </b-form-group>

        <b-form-group
          label="Registration Terms and Conditions"
          label-for="cna_registration_terms"
          label-class="form-label"
        >
          <editor
            :init="{
              height: 400,
              menubar: 'edit view insert format tools table help',
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
            }"
            v-model="cnaAccDetails.registration_terms"
            placeholder="Enter student registration terms and conditions."
          />
        </b-form-group>

        <b-form-group label="Cancellation/Refund Terms" label-for="cancellation_terms" label-class="form-label">
          <editor
            :init="{
              height: 400,
              menubar: 'edit view insert format tools table help',
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
            }"
            v-model="cnaAccDetails.cancellation_terms"
            placeholder="Enter student cancellation/refund policy."
          />
        </b-form-group>

        <!-- SendGrid settings -->
        <page-separator title="SendGrid Settings" />

        <b-form-group label="SendGrid API Key" label-for="cna_sendgrid_api_key" label-class="form-label">
          <b-form-input
            id="cna_sendgrid_api_key"
            placeholder="Enter SendGrid API Key"
            v-model.trim="cnaAccDetails.sendgrid_api_key"
            :state="
              !$v.cnaAccDetails.sendgrid_api_key.required && $v.cnaAccDetails.sendgrid_api_key.$dirty ? false : null
            "
          />

          <b-form-invalid-feedback
            v-if="cnaAccDetails.sendgrid_from_email"
            :state="
              (!$v.cnaAccDetails.sendgrid_api_key.required && $v.cnaAccDetails.sendgrid_api_key.$dirty) ||
              (!$v.cnaAccDetails.sendgrid_from_email.required && $v.cnaAccDetails.sendgrid_from_email.$dirty)
                ? false
                : null
            "
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group label="SendGrid Verified Email Address" label-for="cna_contact_email" label-class="form-label">
          <b-form-input
            id="cna_sendgrid_from_email"
            placeholder="Enter the email address verified on SendGrid account"
            v-model.trim="cnaAccDetails.sendgrid_from_email"
            :state="
              (!$v.cnaAccDetails.sendgrid_from_email.required || !$v.cnaAccDetails.sendgrid_from_email.email) &&
              $v.cnaAccDetails.sendgrid_from_email.$dirty
                ? false
                : null
            "
          />

          <b-form-invalid-feedback
            v-if="cnaAccDetails.sendgrid_api_key"
            :state="
              (!$v.cnaAccDetails.sendgrid_api_key.required && $v.cnaAccDetails.sendgrid_api_key.$dirty) ||
              (!$v.cnaAccDetails.sendgrid_from_email.required && $v.cnaAccDetails.sendgrid_from_email.$dirty)
                ? false
                : null
            "
            >This field is required.</b-form-invalid-feedback
          >
          <b-form-invalid-feedback v-else>Invalid email address.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('authMsgs.password')"
          label-for="password"
          label-class="form-label"
          v-if="$route.params.id && !cnaAccRes"
        >
          <b-alert variant="soft-primary px-12pt" show>
            <div class="d-flex align-items-center justify-content-between">
              <span class="text-black-100"
                >Auto-generated password: <strong>{{ owner.password }}</strong></span
              >

              <span v-if="isPassCopied" class="text-success"><md-icon>check</md-icon> Copied</span>
              <a href="#" @click.prevent="copyPass" v-else v-b-popover.hover.top="'Copy Password'">
                <md-icon>content_copy</md-icon>
              </a>
            </div>
          </b-alert>
        </b-form-group>
      </div>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.query.signupRequestId ? 'Approve' : $route.params.id ? $t('update') : $t('add') }}</span>
      </b-btn>
    </b-form>

    <partnered-orgs-modal
      :showModal="showPartneredOrgsModal"
      :orgs="orgArray"
      :refOrgs="refArray"
      :msg="message"
      @close="hidePartneredOrgsModal"
      @closeUpdate="hideUpdatePartneredOrgsModal"
    />
  </div>
</template>

<script>
import axios from 'axios';
import hexRgb from 'hex-rgb';
import { countries } from 'countries-list';
import { get, map } from 'lodash';
import { mapActions } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Editor from '@tinymce/tinymce-vue';
import generator from 'generate-password';
import { requiredIf, email } from 'vuelidate/lib/validators';
import Page from '@/components/Page.vue';
import {
  PASSWORD_VALIDATIONS,
  PASSWORD_ALL_NUMERIC_REGEX,
  PROFILE_VISIBILITY_OPTIONS,
  INSTITUTION_TYPE_OPTIONS,
  CNA_PAYMENT_PLANS,
  SCHOOL_OFFERINGS,
  SCHOOL_DEF_CANCEL_POLICY
} from '@/common/constants';
import VSwatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.css';
var UsaStates = require('usa-states').UsaStates;
import PartneredOrgsModal from './PartneredOrgsModal.vue';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: {
    PageHeader,
    PageSeparator,
    FmvAvatar,
    VSwatches,
    Editor,
    PartneredOrgsModal,
    ImageUploader
  },
  extends: Page,

  data() {
    return {
      PROFILE_VISIBILITY_OPTIONS,
      INSTITUTION_TYPE_OPTIONS,
      CNA_PAYMENT_PLANS,
      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,
      swatches: [
        ['#1FBC9C', '#1CA085', '#2ECC70', '#3398DB'],
        ['#00b3e6', '#8E43AD', '#3D556E', '#222F3D'],
        ['#214e6f', '#F39C19', '#E84B3C', '#C0382B'],
        ['#DDE6E8', '#BDC3C8', '#C0382B', '#868e96']
      ],
      areOrgsLoading: false,
      allOrgs: [],
      title: this.$route.query.signupRequestId
        ? 'Approve School Account'
        : this.$route.params.id
        ? this.$t('schoolMsgs.editSchool')
        : this.$t('schoolMsgs.addSchool'),
      owner: {
        firstName: '',
        lastName: '',
        email: '',
        password: ''
      },
      isPictureLoading: false,
      photo_gallery: [],
      school: {
        skill_share_id: '',
        name: '',
        email: '',
        phone: '',
        websiteUrl: '',
        zipcode: '',
        address: '',
        city: '',
        state: '',
        subdomain: '',
        country: 'United States',
        logoUrl: null,
        small_logo_url: null,
        profileBgImgUrl: null,
        bestSchoolDesc: null,
        partnershipOverviewVideoUrl: null,
        partnershipImageUrl: null,
        primaryColor: '#00b3e6',
        secondaryColor: '#868e96',
        darkColor: '#214e6f',
        is_profile_visible: true,
        bio: '',
        short_bio: '',
        payment_plan: null,
        pp_home_title: 'Student Center',
        pp_home_description:
          'Our school offers students a variety of support services to ensure you get the most out of your training. Login to get started!',
        pp_testimonial_title: 'Testimonials',
        pp_testimonial_description: 'Read about the career success others have had after completing our program.',

        offered_programs: [],
        organizations: [],
        pp_cta_link_text: 'Login',
        partner_initial_fee: 0,
        partner_recurring_fee: 0,
        partner_reference_fee: 0,
        partnership_terms:
          'By agreeing to partner with this training provider, you are entering into a mutually beneficial agreement to post employment opportunities and recruit aspiring professionals. You will be able to utilize the resources on the Transition platform to attract top talent and establish your brand as an employer of choice.',

        // Student demo account credentials
        demo_password: null,
        demo_email: null,
        std_portal_uni: true
      },
      refOrgs: [],
      message: '',
      inappPaymentEnable: false,
      cnaAcc: false,
      cnaAccRes: false,
      countryOptions: [],
      stateOptions: [],
      paymentPlanOptions: [],
      schoolTypeOptions: [
        { value: 'local', title: 'Local' },
        { value: 'national_partner', title: 'National Partner' }
      ],
      errorStates: {
        password: null,
        ownerEmail: null
      },
      passErrorCode: null,
      isPassCopied: false,
      showStdDemoPass: false,
      isPartnershipVideoUploading: false,
      isPartnershipImageUploading: false,

      freePartnership: true,
      isLoading: false,
      isLogoUploading: false,
      isProfileBgImgUploading: false,
      isCnaProfileBgImgUploading: false,
      isSmallLogoUploading: false,
      isBestSchoolImgUploading: false,
      uploadFile: {
        logo: null,
        profileBgImage: null,
        cnaProfileBgImage: null,
        smallLogo: null,
        BestSchoolImg: null,
        photoGalleryImg: null,
        partnershipOverviewVideo: null,
        partnershipImage: null
      },
      uploadPercent: { logo: 0, profileBgImage: 0, cnaProfileBgImage: 0, smallLogo: 0, photoGalleryImg: 0 },
      uploadCancelTokenSource: {
        logo: null,
        profileBgImage: null,
        cnaProfileBgImage: null,
        smallLogo: null,
        photoGalleryImg: null
      },
      allPrograms: [],
      areProgramsLoading: false,
      arePaymentPlansLoading: false,
      areBestStatesLoading: false,
      areBestCitiesLoading: false,
      BestSchoolOfferingsOptions: SCHOOL_OFFERINGS,

      place_id: null,
      std_bot_api_key: null,
      enrollLink: null,
      shortReview: null,
      bestStates: [],
      bestStateOptions: [],
      bestCities: [],
      bestCitiesOptions: [],
      bestSchoolOfferings: [],
      otherOfferings: null,
      fromClassTuition: null,
      toClassTuition: null,
      enrollBtn: true,
      websiteBtn: true,
      bestSchoolImgUrl: null,
      prevStateLength: null,
      schoolReview: '',
      passRatio: null,
      instituitionType: 'other',
      avgProgramWeeks: null,
      refArray: [],
      orgArray: [],
      cnaAccDetails: {
        paymentPlan: null,
        allPaymentPlans: [],
        arePaymentPlansLoading: false,
        published: false,
        linkedWeb: '',
        sendgrid_api_key: null,
        sendgrid_from_email: null,
        customerPortalLink: '',
        profileBgImageUrl: null,
        pp_home_title: 'Welcome To Healthcare Enroll',
        pp_home_description: 'Our school offers a variety of programs and class for CNAs and more.',
        pp_registration_desc: null,
        pp_enroll_desc: null,
        pp_products_desc: null,
        visitor_notice_title: null,
        visitor_notice_desc: null,
        pp_cta_link_text: 'Join Healthcare Enroll',
        registration_terms: '',
        showBannerStatus: true,
        school_type: 'local',
        cancellation_terms: SCHOOL_DEF_CANCEL_POLICY
      },
      interestedSchool: {},
      showPartneredOrgsModal: false,
      showUniCareCampus: true
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        {
          text: this.$route.query.signupRequestId ? 'Interested Schools' : this.$t('schoolMsgs.schools'),
          to: { name: this.$route.query.signupRequestId ? 'manage-interested-schools' : 'manage-schools' }
        },
        {
          text: this.$route.query.signupRequestId
            ? 'Approve School Account'
            : this.$route.params.id
            ? this.$t('schoolMsgs.editSchool')
            : this.$t('schoolMsgs.addSchool'),
          active: true
        }
      ];
    },
    arraysHaveSameElements() {
      this.school.organizations.length, this.refOrgs;
      if (this.school.organizations.length !== this.refOrgs.length) {
        return false;
      }

      // Sort the arrays
      const sortedArr1 = this.school.organizations.slice().sort();
      const sortedArr2 = this.refOrgs.slice().sort();

      // Compare elements
      for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
          return false;
        }
      }

      return true;
    },
    isFormLoading() {
      return (
        this.isLoading ||
        this.isLogoUploading ||
        this.isProfileBgImgUploading ||
        this.isSmallLogoUploading ||
        this.isCnaProfileBgImgUploading
      );
    }
  },
  validations() {
    return {
      school: {
        offered_programs: { required: requiredIf(() => this.$route.query.signupRequestId) }
      },
      cnaAccDetails: {
        sendgrid_api_key: { required: requiredIf(() => this.cnaAccDetails.sendgrid_from_email) },
        sendgrid_from_email: { required: requiredIf(() => this.cnaAccDetails.sendgrid_api_key), email },
        paymentPlan: { required: requiredIf(() => this.cnaAcc || this.cnaAccRes) }
      }
    };
  },
  methods: {
    ...mapActions('school', [
      'getSchool',
      'createSchool',
      'updateSchool',
      'getSchoolImageUploadPresignedUrl',
      'getSchoolPaymentPlans',
      'getCNASchoolPaymentPlans',
      'getInterestedSchool',
      'createInterestedSchool'
    ]),
    ...mapActions('location', ['getBestStateOptions', 'getBestCitiesOptions']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('program', ['getAllPrograms']),
    ...mapActions('organization', ['getAllOrganizations']),
    get,
    setImage(file, image) {
      this.uploadFile[image] = file;
      this.uploadImage(image);
    },
    openPartneredOrgsModal() {
      this.showPartneredOrgsModal = true;
      this.refArray = this.allOrgs.filter(el => {
        return this.refOrgs.find(element => {
          return element === el.id;
        });
      });
      this.orgArray = this.allOrgs.filter(el => {
        return this.school.organizations.find(element => {
          return element === el.id;
        });
      });
    },
    hidePartneredOrgsModal() {
      this.showPartneredOrgsModal = false;
    },
    hideUpdatePartneredOrgsModal() {
      this.showPartneredOrgsModal = false;
      this.update({
        name: this.school.name,
        contact_email: this.school.email,
        phone: this.school.phone,
        website_url: this.school.websiteUrl,
        zipcode: this.school.zipcode,
        address: this.school.address,
        city: this.school.city,
        state: this.school.state,
        country: this.school.country,
        skill_share_id: this.school.skill_share_id,
        logo_url: this.school.logoUrl,
        small_logo_url: this.school.small_logo_url,
        profile_bg_img_url: this.school.profileBgImgUrl,
        primary_color: this.school.primaryColor,
        secondary_color: this.school.secondaryColor,
        dark_color: this.school.darkColor,
        dark_color_rgb: hexRgb(this.school.darkColor, { alpha: 0.5 }),
        is_profile_visible: this.school.is_profile_visible,
        bio: this.school.bio,
        short_bio: this.school.short_bio,
        pp_home_title: this.school.pp_home_title,
        pp_home_description: this.school.pp_home_description,
        pp_testimonial_title: this.school.pp_testimonial_title,
        pp_testimonial_description: this.school.pp_testimonial_description,

        offered_programs: this.school.offered_programs,
        organizations: this.school.organizations,
        sch_payment_plan: this.school.payment_plan,
        pp_cta_link_text: this.school.pp_cta_link_text,
        partner_initial_fee: this.freePartnership ? 0 : this.school.partner_initial_fee,
        partner_recurring_fee: this.freePartnership ? 0 : this.school.partner_recurring_fee,
        partner_reference_fee: this.school.partner_reference_fee,
        partnership_terms: this.school.partnership_terms,
        best_school_desc: this.school.bestSchoolDesc,
        school_review: this.schoolReview,

        best_cities: this.bestCities.map(city => city.id),
        best_sch_img: this.bestSchoolImgUrl,
        demo_email: this.school.demo_email,
        demo_password: this.school.demo_password,
        place_id: this.place_id,
        std_bot_api_key: this.std_bot_api_key,
        enrollment_link: this.enrollLink,
        short_sch_review: this.shortReview,
        best_offering: this.bestSchoolOfferings,
        other_best_offering: this.otherOfferings,
        class_tuition_from: this.fromClassTuition,
        class_tuition_to: this.toClassTuition,
        enroll_btn_active: this.enrollBtn,
        website_btn_active: this.websiteBtn,
        pass_rate: this.passRatio,
        institution_type: this.instituitionType,
        avg_program_weeks: this.avgProgramWeeks,
        photo_gallery: this.photo_gallery,
        inapp_pymnt_enable: this.inappPaymentEnable,
        cna_school: this.cnaAcc,
        cna_details: {
          sch_payment_plan: get(this.cnaAccDetails.paymentPlan, 'id'),
          ...(get(this.cnaAccDetails.paymentPlan, 'title') === CNA_PAYMENT_PLANS.ADVANCED && {
            school_linked_site: {
              wbst_domain: this.cnaAccDetails.linkedWeb,
              is_published: this.cnaAccDetails.published
            }
          }),
          customer_portal_link: this.cnaAccDetails.customerPortalLink
            ? this.cnaAccDetails.customerPortalLink.startsWith('https://')
              ? this.cnaAccDetails.customerPortalLink
              : `https://${this.cnaAccDetails.customerPortalLink}`
            : '',
          profile_bg_img_url: this.cnaAccDetails.profileBgImageUrl,

          pp_home_title: this.cnaAccDetails.pp_home_title,
          pp_home_description: this.cnaAccDetails.pp_home_description,

          pp_registration_desc: this.cnaAccDetails.pp_registration_desc,
          // pp_enroll_desc: this.cnaAccDetails.pp_enroll_desc,
          pp_products_desc: this.cnaAccDetails.pp_products_desc,
          visitor_notice_title: this.cnaAccDetails.visitor_notice_title,
          visitor_notice_desc: this.cnaAccDetails.visitor_notice_desc,
          pp_cta_link_text: this.cnaAccDetails.pp_cta_link_text,
          registration_terms: this.cnaAccDetails.registration_terms,
          sendgrid_from_email: this.cnaAccDetails.sendgrid_from_email,
          sendgrid_api_key: this.cnaAccDetails.sendgrid_api_key,
          show_banner_status: this.cnaAccDetails.showBannerStatus,
          school_type: this.cnaAccDetails.school_type,
          cancellation_terms: this.cnaAccDetails.cancellation_terms,

          ...(this.cnaAcc && !this.cnaAccRes && { password: this.owner.password })
        }
      });
    },
    removeVisual(index) {
      this.photo_gallery?.splice(index, 1);
    },
    async fetchOrgs() {
      this.areOrgsLoading = true;

      const response = await this.getAllOrganizations();
      this.allOrgs = response.data;

      this.areOrgsLoading = false;
    },
    upload(file) {
      this.uploadFile['photoGalleryImg'] = file;
      this.uploadImage('photoGalleryImg');
    },
    async fetchPaymentPlans() {
      this.arePaymentPlansLoading = true;
      const response = await this.getSchoolPaymentPlans();
      this.paymentPlanOptions = response.data;

      this.school.payment_plan = this.paymentPlanOptions.find(paymentPlan => paymentPlan.title === 'Free Plan').id;

      this.arePaymentPlansLoading = false;
    },
    customReduce(bestCities) {
      return bestCities;
    },
    async fetchInterestedSchool() {
      const res = await this.getInterestedSchool(this.$route.query.signupRequestId);

      this.interestedSchool = res.data;
      this.school.small_logo_url = this.interestedSchool.school_info.small_logo_url;
      this.school.logoUrl = this.interestedSchool.school_info.logo_url;
      this.school.phone = this.interestedSchool.school_info.phone;
      this.school.websiteUrl = this.interestedSchool.school_info.website_url;
      this.school.name = this.interestedSchool.school_info.name;
      this.school.email = get(this.interestedSchool, 'school_info.email');
      this.school.zipcode = this.interestedSchool.school_info.zipcode;
      this.school.address = this.interestedSchool.school_info.address;
      this.school.city = this.interestedSchool.school_info.city;
      this.school.state = this.interestedSchool.school_info.state;
      this.school.country = this.interestedSchool.school_info.country;
      this.school.offered_programs = get(this.interestedSchool, 'school_info.programs')
        ? get(this.interestedSchool, 'school_info.programs')
        : [get(this.interestedSchool, 'school_info.offered_program')];
      this.fromClassTuition = this.interestedSchool.school_info.from_class_tuition;
      this.toClassTuition = this.interestedSchool.school_info.to_class_tuition;
      this.avgProgramWeeks = this.interestedSchool.school_info.avg_program_weeks;
      this.bestSchoolOfferings = this.interestedSchool.school_info.best_school_offerings;
      this.otherOfferings = this.interestedSchool.school_info.other_offerings;
      this.owner.firstName = this.interestedSchool.school_info.owner.first_name;
      this.owner.lastName = this.interestedSchool.school_info.owner.last_name;
      this.owner.email = this.interestedSchool.school_info.owner.email;
      this.bestStates = this.interestedSchool.best_states;
      this.bestCities = this.interestedSchool.best_cities;
    },
    async getCities() {
      this.areBestCitiesLoading = true;
      if (this.bestStates.length) {
        const bestCitiesApi = await this.getBestCitiesOptions({ states: this.bestStates });
        this.bestCitiesOptions = bestCitiesApi.data;
        if (this.bestStates.length < this.prevStateLength) {
          this.bestStates.forEach(obj => {
            this.bestCities = this.bestCities.filter(city => city.state.id === obj);
          });
          this.prevStateLength = this.bestStates.length;
        } else {
          this.prevStateLength = this.bestStates.length;
        }
      } else if (!this.bestStates.length) {
        this.bestCitiesOptions = [];
        this.bestCities = [];
        this.prevStateLength = this.bestStates.length;
      }
      this.areBestCitiesLoading = false;
    },
    async fetchCNAPaymentOptions() {
      this.cnaAccDetails.arePaymentPlansLoading = true;

      try {
        const payment_plans = await this.getCNASchoolPaymentPlans();
        this.cnaAccDetails.allPaymentPlans = payment_plans.data;
      } catch (e) {
        // USE if needed
      }

      this.cnaAccDetails.arePaymentPlansLoading = false;
    },
    async fetchBestStatesOptions() {
      this.areBestStatesLoading = true;

      const bestStates = await this.getBestStateOptions({});
      this.bestStateOptions = bestStates.data;

      this.areBestStatesLoading = false;
    },
    async fetchPrograms() {
      this.areProgramsLoading = true;

      const response = await this.getAllPrograms({ ordering: 'id' });
      this.allPrograms = response.data;
      this.areProgramsLoading = false;
    },
    async update(data) {
      try {
        await this.updateSchool({
          id: this.$route.params.id,
          data
        });
        this.makeToast({ variant: 'success', msg: this.$t('schoolMsgs.schoolProfileUpdated') });
        setTimeout(() => this.$router.push({ name: 'manage-schools' }), 250);
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.errorStates.password = this.errorStates.ownerEmail = null;
        if (!this.$route.params.id && this.owner.password.length < 5) {
          this.passErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_MIN_LENGTH;
          this.errorStates.password = false;
        } else if (!this.$route.params.id && PASSWORD_ALL_NUMERIC_REGEX.test(this.owner.password)) {
          this.passErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_ALL_NUMERIC;
          this.errorStates.password = false;
        } else {
          try {
            const data = {
              name: this.school.name,
              contact_email: this.school.email,
              phone: this.school.phone,
              website_url: this.school.websiteUrl,
              zipcode: this.school.zipcode,
              address: this.school.address,
              city: this.school.city,
              state: this.school.state,
              country: this.school.country,
              skill_share_id: this.school.skill_share_id,
              logo_url: this.school.logoUrl,
              small_logo_url: this.school.small_logo_url,
              profile_bg_img_url: this.school.profileBgImgUrl,
              primary_color: this.school.primaryColor,
              secondary_color: this.school.secondaryColor,
              dark_color: this.school.darkColor,
              dark_color_rgb: hexRgb(this.school.darkColor, { alpha: 0.5 }),
              is_profile_visible: this.school.is_profile_visible,
              bio: this.school.bio,
              short_bio: this.school.short_bio,
              pp_home_title: this.school.pp_home_title,
              pp_home_description: this.school.pp_home_description,
              pp_testimonial_title: this.school.pp_testimonial_title,
              pp_testimonial_description: this.school.pp_testimonial_description,

              offered_programs: this.school.offered_programs,
              organizations: this.school.organizations,
              sch_payment_plan: this.school.payment_plan,
              pp_cta_link_text: this.school.pp_cta_link_text,
              partner_initial_fee: this.freePartnership ? 0 : this.school.partner_initial_fee,
              partner_recurring_fee: this.freePartnership ? 0 : this.school.partner_recurring_fee,
              partner_reference_fee: this.school.partner_reference_fee,
              partnership_terms: this.school.partnership_terms,
              best_school_desc: this.school.bestSchoolDesc,
              school_review: this.schoolReview,
              std_portal_uni: this.school.std_portal_uni,
              best_cities: this.bestCities.map(city => city.id),
              best_sch_img: this.bestSchoolImgUrl,
              demo_email: this.school.demo_email,
              demo_password: this.school.demo_password,
              pship_overview_video: this.school.partnershipOverviewVideoUrl,
              pship_overview_thumbnail: this.school.partnershipImageUrl,
              place_id: this.place_id,
              std_bot_api_key: this.std_bot_api_key,
              enrollment_link: this.enrollLink,
              short_sch_review: this.shortReview,
              best_offering: this.bestSchoolOfferings,
              other_best_offering: this.otherOfferings,
              class_tuition_from: this.fromClassTuition,
              class_tuition_to: this.toClassTuition,
              enroll_btn_active: this.enrollBtn,
              website_btn_active: this.websiteBtn,
              pass_rate: this.passRatio,
              institution_type: this.instituitionType,
              avg_program_weeks: this.avgProgramWeeks,
              photo_gallery: this.photo_gallery,
              inapp_pymnt_enable: this.inappPaymentEnable,
              care_campus_sch: this.showUniCareCampus,
              cna_school: this.cnaAcc,
              cna_details: {
                sch_payment_plan: get(this.cnaAccDetails.paymentPlan, 'id'),
                ...(get(this.cnaAccDetails.paymentPlan, 'title') === CNA_PAYMENT_PLANS.ADVANCED && {
                  school_linked_site: {
                    wbst_domain: this.cnaAccDetails.linkedWeb,
                    is_published: this.cnaAccDetails.published
                  }
                }),
                customer_portal_link: this.cnaAccDetails.customerPortalLink
                  ? this.cnaAccDetails.customerPortalLink.startsWith('https://')
                    ? this.cnaAccDetails.customerPortalLink
                    : `https://${this.cnaAccDetails.customerPortalLink}`
                  : '',
                profile_bg_img_url: this.cnaAccDetails.profileBgImageUrl,

                pp_home_title: this.cnaAccDetails.pp_home_title,
                pp_home_description: this.cnaAccDetails.pp_home_description,

                pp_registration_desc: this.cnaAccDetails.pp_registration_desc,
                // pp_enroll_desc: this.cnaAccDetails.pp_enroll_desc,
                pp_products_desc: this.cnaAccDetails.pp_products_desc,
                visitor_notice_title: this.cnaAccDetails.visitor_notice_title,
                visitor_notice_desc: this.cnaAccDetails.visitor_notice_desc,
                pp_cta_link_text: this.cnaAccDetails.pp_cta_link_text,
                registration_terms: this.cnaAccDetails.registration_terms,
                sendgrid_from_email: this.cnaAccDetails.sendgrid_from_email,
                sendgrid_api_key: this.cnaAccDetails.sendgrid_api_key,
                show_banner_status: this.cnaAccDetails.showBannerStatus,
                school_type: this.cnaAccDetails.school_type,
                cancellation_terms: this.cnaAccDetails.cancellation_terms,

                ...(this.cnaAcc && !this.cnaAccRes && { password: this.owner.password })
              }
            };

            if (this.$route.params.id) {
              if (!this.arraysHaveSameElements) {
                this.openPartneredOrgsModal();
              } else {
                this.update(data);
                this.makeToast({ variant: 'success', msg: this.$t('schoolMsgs.schoolProfileUpdated') });
              }
            } else if (this.$route.query.signupRequestId) {
              await this.createInterestedSchool({
                signup_request: this.$route.query.signupRequestId,
                universities: this.interestedSchool.interested_universites,
                ...data,
                owner: {
                  email: this.owner.email,
                  first_name: this.owner.firstName,
                  last_name: this.owner.lastName,
                  password: this.owner.password,
                  phone: this.school.phone
                }
              });
              this.makeToast({ variant: 'success', msg: 'School Approved' });
              setTimeout(() => this.$router.push({ name: 'manage-interested-schools' }), 250);
            } else {
              await this.createSchool({
                ...data,
                owner: {
                  email: this.owner.email,
                  first_name: this.owner.firstName,
                  last_name: this.owner.lastName,
                  password: this.owner.password,
                  phone: this.school.phone
                }
              });
              this.makeToast({ variant: 'success', msg: this.$t('schoolMsgs.schoolAdded') });
              setTimeout(() => this.$router.push({ name: 'manage-schools' }), 250);
            }
          } catch (err) {
            if (get(err, 'response.data.owner')) {
              this.errorStates.ownerEmail = false;
            }
            this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
          }
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    },

    async uploadImage(uploadType) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      this.isLogoUploading = uploadType === 'logo';
      this.isSmallLogoUploading = uploadType === 'smallLogo';
      this.isProfileBgImgUploading = uploadType === 'profileBgImage';
      this.isCnaProfileBgImgUploading = uploadType === 'cnaProfileBgImage';
      this.isBestSchoolImgUploading = uploadType === 'BestSchoolImg';
      this.isPictureLoading = uploadType === 'photoGalleryImg';
      this.isPartnershipVideoUploading = uploadType === 'partnershipOverviewVideo';
      this.isPartnershipImageUploading = uploadType === 'partnershipImage';

      try {
        const urlResp = await this.getSchoolImageUploadPresignedUrl({
          file_name: this.uploadFile[uploadType].name,
          content_type: this.uploadFile[uploadType].type
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadFile[uploadType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token
          }
        });
        if (uploadType === 'logo') {
          this.school.logoUrl = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'smallLogo') {
          this.school.small_logo_url = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'BestSchoolImg') {
          this.bestSchoolImgUrl = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'photoGalleryImg') {
          this.photo_gallery.push(urlResp.upload_url.split('?')[0]);
        } else if (uploadType === 'cnaProfileBgImage') {
          this.cnaAccDetails.profileBgImageUrl = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'partnershipImage') {
          this.school.partnershipImageUrl = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'partnershipOverviewVideo') {
          this.school.partnershipOverviewVideoUrl = urlResp.upload_url.split('?')[0];
        } else {
          this.school.profileBgImgUrl = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'logo') this.isLogoUploading = false;
      else if (uploadType === 'smallLogo') this.isSmallLogoUploading = false;
      else if (uploadType === 'BestSchoolImg') this.isBestSchoolImgUploading = false;
      else if (uploadType === 'photoGalleryImg') this.isPictureLoading = false;
      else if (uploadType === 'cnaProfileBgImage') this.isCnaProfileBgImgUploading = false;
      else if (uploadType === 'partnershipOverviewVideo') this.isPartnershipVideoUploading = false;
      else if (uploadType === 'partnershipImage') this.isPartnershipImageUploading = false;
      else this.isProfileBgImgUploading = false;
    },

    async copyPass() {
      try {
        await navigator.clipboard.writeText(this.owner.password);
        this.isPassCopied = true;
        setTimeout(() => (this.isPassCopied = false), 2000);
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    }
  },

  async mounted() {
    this.isLoading = true;
    this.fetchPrograms();
    this.fetchPaymentPlans();
    this.fetchCNAPaymentOptions();
    this.fetchOrgs();
    var usStates = new UsaStates();
    this.stateOptions = this.stateOptions.concat(
      map(usStates.states, state => ({
        value: state.name,
        text: state.name
      }))
    );
    this.countryOptions = this.countryOptions.concat(
      map(countries, country => ({
        value: country.name,
        text: country.name
      }))
    );

    // Auto-generate password.
    if (!this.$route.params.id) {
      this.owner.password = generator.generate({ length: 10, numbers: true, strict: true });
    }

    if (this.$route.query.signupRequestId) {
      this.fetchInterestedSchool();
    }

    try {
      await this.fetchBestStatesOptions();

      if (this.$route.params.id) {
        const resp = (await this.getSchool(this.$route.params.id)).data;
        this.school.name = resp.name;
        this.school.email = resp.contact_email;
        this.school.phone = resp.phone;
        this.school.websiteUrl = resp.website_url;
        this.school.zipcode = resp.zipcode;
        this.school.address = resp.address;
        this.school.city = resp.city;
        this.school.state = resp.state;
        this.school.country = resp.country;
        this.school.logoUrl = resp.logo_url;
        this.school.skill_share_id = resp.skill_share_id;
        this.school.small_logo_url = resp.small_logo_url;
        this.school.subdomain = resp.subdomain.subdomain_url;
        this.school.profileBgImgUrl = resp.profile_bg_img_url;
        this.school.primaryColor = resp.primary_color;
        this.school.secondaryColor = resp.secondary_color;
        this.school.darkColor = resp.dark_color;
        this.school.is_profile_visible = resp.is_profile_visible;
        this.school.bio = resp.bio;
        this.school.short_bio = resp.short_bio;
        this.school.pp_home_title = resp.pp_home_title;
        this.school.pp_home_description = resp.pp_home_description;
        this.school.pp_testimonial_title = resp.pp_testimonial_title;
        this.school.pp_testimonial_description = resp.pp_testimonial_description;
        this.school.bestSchoolDesc = resp.best_school_desc;
        this.school.offered_programs = resp.offered_programs.map(prg => prg.id);
        this.school.payment_plan = get(resp, 'sch_payment_plan.id');
        this.school.pp_cta_link_text = resp.pp_cta_link_text;
        this.school.organizations = this.refOrgs = resp.organizations;
        this.school.partner_initial_fee = resp.partner_initial_fee;
        this.school.partner_recurring_fee = resp.partner_recurring_fee;
        this.school.partner_reference_fee = resp.partner_reference_fee;
        this.school.demo_email = resp.demo_email;
        this.school.demo_password = resp.demo_password;
        this.school.std_portal_uni = resp.std_portal_uni;

        this.school.partnershipOverviewVideoUrl = resp.pship_overview_video;
        this.school.partnershipImageUrl = resp.pship_overview_thumbnail;

        this.freePartnership = resp.partner_initial_fee === 0 && resp.partner_recurring_fee === 0;

        this.school.partnership_terms = resp.partnership_terms;

        this.schoolReview = resp.school_review;

        this.bestCities = resp.best_cities;
        this.bestStates = resp.states;
        this.bestSchoolImgUrl = resp.best_sch_img;

        this.place_id = resp.place_id;
        this.std_bot_api_key = resp.std_bot_api_key;
        this.enrollLink = resp.enrollment_link;
        this.bestSchoolOfferings = resp.best_offering;
        this.otherOfferings = resp.other_best_offering;
        this.fromClassTuition = resp.class_tuition_from;
        this.toClassTuition = resp.class_tuition_to;
        this.enrollBtn = resp.enroll_btn_active;
        this.websiteBtn = resp.website_btn_active;
        this.passRatio = resp.pass_rate;
        this.shortReview = resp.short_sch_review;
        this.instituitionType = resp.institution_type;
        this.avgProgramWeeks = resp.avg_program_weeks;
        this.photo_gallery = resp.photo_gallery;
        this.inappPaymentEnable = resp.inapp_pymnt_enable;
        this.showUniCareCampus = resp.care_campus_sch;

        this.cnaAcc = resp.cna_school;
        this.cnaAccRes = this.cnaAcc;
        if (this.cnaAcc && resp.cna_details) {
          this.cnaAccDetails.paymentPlan = get(resp.cna_details, 'sch_payment_plan');
          this.cnaAccDetails.published = get(resp.cna_details.school_linked_site, 'is_published');
          this.cnaAccDetails.linkedWeb = get(resp.cna_details.school_linked_site, 'wbst_domain');
          this.cnaAccDetails.customerPortalLink = resp.cna_details.customer_portal_link;
          this.cnaAccDetails.profileBgImageUrl = resp.cna_details.profile_bg_img_url;
          this.cnaAccDetails.pp_home_title = resp.cna_details.pp_home_title;
          this.cnaAccDetails.pp_home_description = resp.cna_details.pp_home_description;

          this.cnaAccDetails.pp_registration_desc = resp.cna_details.pp_registration_desc;
          // this.cnaAccDetails.pp_enroll_desc = resp.cna_details.pp_enroll_desc;
          this.cnaAccDetails.pp_products_desc = resp.cna_details.pp_products_desc;
          this.cnaAccDetails.visitor_notice_title = resp.cna_details.visitor_notice_title;
          this.cnaAccDetails.visitor_notice_desc = resp.cna_details.visitor_notice_desc;
          this.cnaAccDetails.pp_cta_link_text = resp.cna_details.pp_cta_link_text;
          this.cnaAccDetails.registration_terms = resp.cna_details.registration_terms;
          this.cnaAccDetails.sendgrid_from_email = resp.cna_details.sendgrid_from_email;
          this.cnaAccDetails.sendgrid_api_key = resp.cna_details.sendgrid_api_key;
          this.cnaAccDetails.showBannerStatus = resp.cna_details.show_banner_status;
          this.cnaAccDetails.school_type = resp.cna_details.school_type;
          this.cnaAccDetails.cancellation_terms = resp.cna_details.cancellation_terms;
        }

        // Auto-generate password.
        if (!this.cnaAccRes) {
          this.owner.password = generator.generate({ length: 10, numbers: true, strict: true });
        }
      }
      if (this.bestStates.length) {
        this.getCities();
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      this.$router.push({ name: 'manage-schools' });
    }

    this.isLoading = false;
  }
};
</script>
